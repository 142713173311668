import React from 'react';
import styled from 'styled-components';
import Text from '@rileyyy/react-typography';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';

import Container from '../components/Container';
import Seo from '../components/Seo';
import ProjectCard from '../components/ProjectCard';
import config from '../config';

import { typography } from '../styles/typography';
import { Media } from '../helpers';

const ALL_PROJECTS = [
  {
    title: 'tpscalls.live',
    description:
      'Real-time mapping of locations where the Toronto Police have responded to a call for service.',
    links: [
      {
        type: 'web',
        url: 'https://www.tpscalls.live',
      },
      {
        type: 'github',
        url: 'https://github.com/rdrnt/tps-calls',
      },
    ],
    imageName: 'tpscalls',
    tags: ['TypeScript', 'React', 'React Native', 'Redux', 'Firebase'],
  },
  {
    title: 'Socialfy',
    description:
      "Your Spotify listening habits, more social. Share what you're currently listening to, your top artists, and more. No longer active.",
    links: [
      {
        type: 'web',
        url: 'https://www.socialfy.app',
      },
      {
        type: 'github',
        url: 'https://github.com/rdrnt/socialfy-frontend',
      },
    ],
    imageName: 'socialfy',
    tags: ['JavaScript', 'React', 'Context', 'Firebase', 'styled-components'],
  },
  {
    title: 'WiiLED',
    description:
      'A homebrew channel app to demonstrate manipulating the Wii disc drive LED',
    links: [
      {
        type: 'github',
        url: 'https://github.com/rdrnt/WiiLED',
      },
    ],
    imageName: 'wiiled',
    tags: ['Demo', 'C', 'Homebrew'],
  },
  {
    title: 'Canada Slippi Leaderboards',
    description:
      'Leaderboards for Slippi ranked (Super Smash Bros. Melee online)',
    links: [
      {
        type: 'web',
        url: 'https://rdrnt.github.io/canada-slippi-ranked-leaderboards/#/',
      },
      {
        type: 'github',
        url: 'https://github.com/rdrnt/canada-slippi-ranked-leaderboards',
      },
    ],
    imageName: 'crsl',
    tags: ['JavaScript', 'React', 'SSBM'],
  },
  {
    title: 'Superslam',
    description:
      'My first Cydia tweak that plays the popular John Cena meme & makes him appear with Activator.',
    links: [
      {
        type: 'github',
        url: 'https://github.com/rdrnt/superslam',
      },
    ],
    imageName: 'superslam',
    tags: ['Objective-C', 'Logos', 'iOS', 'Cydia'],
  },
];

const Content = styled.div`
  height: 100%;
  width: 100%;

  > div {
    height: 100%;
    width: 100%;

    > h1 {
      ${typography.h2};
      margin-bottom: ${config.sizes.spacing * 2}px;

      ${Media.MOBILE`
        ${typography.h3};
        margin-bottom: ${config.sizes.spacing * 2}px;
      `};
    }
  }
`;

const ProjectList = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  > * {
    margin-bottom: ${config.sizes.spacing}px;
  }
`;

function Projects({ data }) {
  // gets the image with the project filename
  const getImageForProject = (name) => {
    const matchingNode = data.allFile.nodes.find((node) => name === node.name);

    if (matchingNode) {
      // console.log('Matching node', matchingNode, getImage(matchingNode));
      return getImage(matchingNode);
    }

    return undefined;
  };

  return (
    <>
      <Seo title="Projects" description="The various creations of mine." />
      <Content>
        <Container>
          <Text tag="h1">Projects</Text>
          <ProjectList>
            {ALL_PROJECTS.map((project) => {
              const projectImage = getImageForProject(project.imageName);

              if (!projectImage) {
                return null;
              }

              return (
                <ProjectCard
                  key={project.title}
                  image={projectImage}
                  {...project}
                />
              );
            })}
          </ProjectList>
        </Container>
      </Content>
    </>
  );
}

export const query = graphql`
  query ProjectImagesQuery {
    allFile(filter: { relativeDirectory: { eq: "projects" } }) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  }
`;

export default Projects;
