import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import Text from '@rileyyy/react-typography';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Tag from '../Tag';
import Icon from '../Icon';

import { Media } from '../../helpers';

import { typography } from '../../styles/typography';

import config from '../../config';

const getIconNameForLinkType = linkType => {
  switch (linkType) {
    case 'web':
      return 'browserLink';
    case 'github':
      return 'github';
    default:
      return '';
  }
};

const Content = styled(motion.div)`
  box-shadow: 1px 2px 7px 0 rgba(0, 0, 0, 0.3);
  width: 275px;
  height: 400px;
  background-color: white;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${Media.MOBILE`
    width: 100%;
  `};
`;

const ImageContent = styled.div`
  width: 100%;
  height: 160px;
`;

const TextContent = styled.div`
  height: auto;
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${config.sizes.spacing / 2}px;
  padding-bottom: 0;

  > p {
    line-height: 20px;
  }
`;

const TitleContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${config.sizes.spacing / 2}px;

  > h4 {
    ${typography.h6};
  }

  > div {
    height: auto;

    > a {
      margin: 0 ${config.sizes.spacing / 3}px;
    }
  }
`;

const TagsContent = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding: ${config.sizes.spacing / 2}px;
  padding-top: 0;
`;

const StyledProjectImage = styled(GatsbyImage)`
  height: 100%;
`;

const ProjectCard = ({ title, description, links, image, tags }) => {
  const imageData = getImage(image);

  return (
    <Content whileHover={{ scale: 1.05 }}>
      <ImageContent>
        <GatsbyImage image={imageData} alt="" style={{ height: '100%' }} />
      </ImageContent>
      <TextContent>
        <TitleContent>
          <Text tag="h4">{title}</Text>
          <div>
            {links.map(linkItem => (
              <a
                key={linkItem.url}
                target="_blank"
                rel="noopener noreferrer"
                href={linkItem.url}
              >
                <Icon
                  name={getIconNameForLinkType(linkItem.type)}
                  color={config.colors.alt}
                />
              </a>
            ))}
          </div>
        </TitleContent>

        <Text tag="p" style={{ color: config.colors.alt }}>
          {description}
        </Text>
      </TextContent>
      <TagsContent>
        {tags.map(tag => (
          <Tag key={tag} label={tag} />
        ))}
      </TagsContent>
    </Content>
  );
};

export default ProjectCard;
