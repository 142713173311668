import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Text from '@rileyyy/react-typography';

import config from '../../config';

const Content = styled.div`
  width: auto;
  padding: ${config.sizes.spacing / 3}px;
  margin: 3px;
  border-radius: 15px;
  background-color: ${props => props.backgroundColor};

  > span {
    white-space: nowrap;
    font-weight: 600;
    font-size: 10px;
    letter-spacing: 0.1em;
  }
`;

const Tag = ({ label, backgroundColor }) => (
  <Content backgroundColor={backgroundColor}>
    <Text tag="span">{label}</Text>
  </Content>
);

Tag.propTypes = {
  backgroundColor: PropTypes.string,
  label: PropTypes.string.isRequired,
};

Tag.defaultProps = {
  backgroundColor: config.colors.background,
};

export default Tag;
